@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scroll::-webkit-scrollbar-track {
  /* background: rgb(255, 255, 255); */
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #4285F4;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar:horizontal {
  width: 5px;
  height: 10px;
}

.scroll::-webkit-scrollbar-track:horizontal {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb:horizontal {
  background: #4285F4;
  border-radius: 10px;
}




.thin-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.thin-scroll::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background: #4285F4;
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar:horizontal {
  width: 5px;
  height: 5px;
}

.thin-scroll::-webkit-scrollbar-track:horizontal {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar-thumb:horizontal {
  background: #4285F4;
  border-radius: 10px;
}

/* Default scrollbar fallback for unsupported browsers like Safari */

.shadow-class {
  outline: #ddd solid 1px;
  outline-offset: 0px;

  /* box-shadow: 0 -1px 0px 0px #ddd, 1px 1px 1px 0px #e9e9e9; */
}

.shadow-col {
  outline: #ddd solid 1px;
  box-shadow: 0 -1px 0 0 #ddd, 0 1px 0 0 #ddd;
  /* Creates top and bottom outline offset */
}


.custom-steps .ant-steps-item-active .ant-steps-item-icon {
  border-color: #1890ff;
  /* Highlight border color */
}

.custom-steps .ant-steps-item-active .ant-steps-item-icon>.ant-steps-icon {
  color: #1890ff;
  /* Highlight icon color */
}

.custom-steps .ant-steps-item-icon {
  margin-inline-end: 0px !important;

  margin-inline-start: 0px !important;
}

.custom-steps .ant-steps-item {
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

.custom-steps .ant-steps-item-title {
  padding-inline-end: 0px !important;
}

.box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.box-shadow-summary {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px -2px, rgba(0, 0, 0, 0.3) 0px 1px 1px -2px;
}



.expandable-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.expandable-content.expanded {
  max-height: 1000px;
  /* or a value that is large enough to show the expanded content */
}



.resizable-table {
  width: 100%;
  border-collapse: collapse;
}



.resizable-table td {
  position: sticky;
  /* top: 0; */
  /* background: #f1f1f1; Background color for sticky headers */
  /* z-index: 1; */
  white-space: nowrap;
  user-select: none;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  /* z-index: 2; */
  background-color: transparent;
}

.plan-select select {
  padding-right: 1.5rem;
  /* Adjust this based on the arrow size */
  line-height: 1.5rem;
  background: url('data:image/svg+xml;utf8,<svg fill="%23000" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat;
  background-position: center;
  /* Adjust this to move the arrow */
  background-size: 1rem;
  appearance: none;
  /* Remove default arrow */
}


.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  background-color: #d5ebff !important;
  border-radius: 10px;
  width: 79px !important;
  color: #4285F4 !important;
}

.ant-layout-sider-trigger {
  background-color: #d5ebff !important;
  border-radius: 10px;
  width: 200px !important;
  color: #4285F4 !important;
}

.ant-layout-sider-trigger:hover {
  background-color: #83c3ff !important;
  color: white !important;
}

.ant-layout-sider-trigger.expanded {
  width: 200px !important;
  /* Width when expanded */
}


#my-tooltip {
  z-index: 99999 !important;
  padding: 2px 5px;
}

.ant-message {
  position: fixed;
  bottom: 20px !important;
  top: unset !important;
}

.ant-upload-list {
  display: none !important;
}

.interval-selector .ant-select-selector {
  border-radius: 30px;
  color: #2563eb !important;
}

.interval-selector .ant-select-selection-placeholder {
  color: #2563eb !important;
}

.unit-selector .ant-select-selector {
  border-radius: 30px;
  color: #2563eb !important;
}

.custom-date-picker .ant-picker-input input {
  font-size: 10px !important;
}


.interval-selector {
  height: 20px;
  font-size: 10px;
  /* Adjust the font size of the select box */
}

.interval-selector .ant-select-selector {
  height: 20px;
  font-size: 10px;
  /* Font size of the placeholder and selected value */
}

.interval-selector .ant-select-item {
  height: 20px;
  font-size: 10px;
  /* Adjust the font size of dropdown items */
}
.shadow-side-panel{
  background-color:rgba(255, 255, 255, 0.5) ;
  box-shadow: 0px 0 100px 100px rgba(255, 255, 255, 0.5);
}

.box-shadow-panel {
  box-shadow:  0px 0px 10px 1px rgba(0, 0, 0, 0.199)
}

.small-select{
  font-size:12px !important;
}

.small-select .ant-select-selector{
  font-size:12px !important;
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 12px solid rgb(198, 0, 0);
  border-left: 12px solid transparent;

}
.triangle-topright:hover {
  border-top: 15px solid rgb(171, 0, 0);
  border-left: 15px solid transparent;
}

.triangle-bottomright {
  width: 0;
  height: 0;
  border-bottom: 12px solid rgb(0, 255, 13);
  border-left: 12px solid transparent;

}
.triangle-bottomright:hover {
  border-bottom: 15px solid rgb(0, 255, 13);
  border-left: 15px solid transparent;
}



.transition-section {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.transition-section.show {
  overflow:visible;
  max-height: 1000px;
  opacity: 1;
}
